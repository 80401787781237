"use client";

import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from "@mui/material";
import * as React from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useConfigStore } from "@/stores/configStore";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";

export interface IAppProps { }

type BottomNavigationAction = {
  path: string;
  label: string;
  icon: string;
  color: string | null
};

const bottomNavigationActions: BottomNavigationAction[] = [
  {
    path: "/",
    label: "หน้าแรก",
    icon: "mdi:home",
    color: null
  },
  {
    path: "/",
    label: "ฝากเงิน",
    icon: "mdi:money",
    color: null
  },
  {
    path: "/",
    label: "โปรโมชั่น",
    icon: "mdi:gift",
    color: null
  },
  {
    path: "/",
    label: "ถอน",
    icon: "mdi:account-cash",
    color: null
  },
  // {
  //   path: "/",
  //   label: "ประวัติ",
  //   icon: "flat-color-icons:view-details",
  //   color: null
  // },
  {
    path: "LINE",
    label: "ติดต่อเรา",
    icon: "mdi:call",
    color: null
  },
];

export default function LayoutButtomNavigation(props: IAppProps) {
  const path = usePathname();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user] = useAuthStore((state) => [state.user!], shallow);
  const [setOpenAppModal, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);

  React.useEffect(() => {
    const index = bottomNavigationActions.findIndex(
      (action) => action.path === path
    );
    setActiveIndex(index);
  }, [path]);

  return (
    <Container
      maxWidth="md"
      sx={{
        p: 0,
        m: 0,
      }}
    >
      <BottomNavigation
        sx={{
          background: (theme) => theme.name === 'preset14'
            ? 'linear-gradient(180deg, #FC9ED3, #d566f0)'
            : theme.gradient[400]
        }}
        showLabels
        value={activeIndex}
        onChange={(event, newValue) => {
          setActiveIndex(newValue);
        }}
      >
        {
          bottomNavigationActions
            .filter(action => user ? true : (action.label === 'หน้าแรก' || action.label === 'ติดต่อเรา'))
            .map((action, index) => (
              <BottomNavigationAction
                component={Link}
                href={
                  action.path == "LINE" ? config?.line_oa_url || "" : action.path
                }
                target={action.path == "LINE" ? "_blank" : "_self"}
                key={index}
                label={action.label}
                sx={{
                  color: (theme) => action.color ? action.color : theme.gradient[800],
                  minWidth: 0,
                  '.Mui-selected': {
                    fontSize: '0.75rem !important'
                  },
                }}
                icon={
                  <Icon
                    id="icon"
                    icon={action.icon || ''}
                    width={24}
                    height={24}
                  />
                }
                onClick={(e) => {
                  if (action.label === 'หน้าแรก' || action.label === 'ติดต่อเรา') {
                    return
                  } else {
                    e.preventDefault()
                    setOpenAppModal(true);

                    if (action.label === 'ฝากเงิน') {
                      setData('deposit');
                    } else {
                      if (action.label === 'โปรโมชั่น') {
                        setData('promotion');
                      } else {
                        setData('withdraw');
                      }
                    }

                    return
                  }
                }}
              />
            ))
        }
      </BottomNavigation>
    </Container>
  );
}
